/* * * * * * * * * * * * * * *
ALL VARIABLE & MIXING
* * * * * * * * * * * * * * */
@isoFontFamily: 'CircularMedium', sans-serif;
@isoFontSize: 14px;

//  Colors
@isoColor--Heading: #323332; // Previous : @isoColor--GreyDarker
@isoColor--Text: #979797; // Previous : @isoColor--GreyDark
@isoColor--TextDark: #797979; //Previous: @isoColor--GreyText
@isoColor--HeadingLight: #595959; // Previous : @isoColor--GreyDarkText
@isoColor--GreyShade: #bababa;
@isoColor--GreyDark: #c1c1c1; // Previous: @isoColor--GreyTextLight
@isoColor--Grey: #D8D8D8;
@isoColor--GreyAlt: #f1f1f1;
@isoColor--GreyLight: #F3F3F3;
@isoColor--DarkWhite: #fafafa; // Previous: @isoColor--GreyLighten
@isoColor--DarkerWhite: #F9F9F9; // Previous: @isoColor--GreyLighter
@isoColor--GreyBackground: #f2f2f2;

@yp-background-color: #212123;
@primary-color : #FFDD00;
@success-color : #00b16a;
@error-color : #f64744;
@warning-color : #ffbf00;

@isoColor--Blue: #42A5F5;
@isoColor--LimeGreen: #7ED321;
@isoColor--Orange: #FEAC01;
@isoColor--Yellow: #FFCA28;
@isoColor--Pink: #F75D81;
@isoColor--Purple: #42299a; // Previous: @isoColor--PurpleDark
@isoColor--DarkBlue: #2d3446;
@isoColor--DarkerBlue: #2b303b;
@isoColor--BlueShade: #39435f;
@isoColor--LightBlue: #788195;
@isoColor--LightBluish: #f1f3f6;
@isoColor--LighterBluish: #f5f6f8;
@isoColor--DarkBluish: #e9ebf1;
@isoColor--Black: #000;

@isoColor--Border: #e9e9e9;
@isoColor--BorderDark: #d8d8d8;
@isoColor--BorderLight: #ebebeb; // // Previous: @isoColor--BorderAlt`

// MIXINS
.isoTransition (@time: 0.3s, @prop: all, @tran: cubic-bezier(0.215, 0.61, 0.355, 1)) {
  -webkit-transition: @prop @time @tran;
  -moz-transition: @prop @time @tran;
  -ms-transition: @prop @time @tran;
  -o-transition: @prop @time @tran;
  transition: @prop @time @tran;
}

// BORDER RADIUS
.isoBorderRadius (@radius : 5px 5px 5px 5px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  -o-border-radius: @radius;
  border-radius: @radius;
}

// DROP SHADOW
.isoBoxShadow (@values) {
  -webkit-box-shadow: @values;
  -moz-box-shadow: @values;
  box-shadow: @values;
}
