/* * * * * * * * * * * * * * * * * * * * *
SELECT
* * * * * * * * * * * * * * * * * * * * */

@import '../../base.less';

.ant-select {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  color: @isoColor--HeadingLight;
  font-size: @isoFontSize - 1;

  .ant-select-selection{
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid @isoColor--Border;
    .isoTransition;

    &.ant-select-selection--single {
      height: 35px;
      position: relative;
      cursor: pointer;
    }

    .ant-select-selection__rendered {
      margin-left: 10px;
      margin-right: 10px;
      line-height: 33px;
    }

    &:hover{
      border-color: @primary-color;
    }
  }

  &.ant-select-focused {
    .ant-select-selection{
      &:focus,
      &:active{
        border-color: @primary-color;
        outline: 0;
        box-shadow: 0 0 0 2px fade(@primary-color, 20%);
      }
    }
  }

  &.ant-select-open {
    .ant-select-selection {
      border-color: @primary-color;
      outline: 0;
      box-shadow: 0 0 0 2px fade(@primary-color, 20%);;
    }
  }

  .ant-select-selection--multiple > ul > li,
  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    margin-top: 4px;
    height: 26px;
    line-height: 26px;
  }

  .ant-select-selection--multiple .ant-select-selection__choice {
    background-color: #f3f3f3;
    color: @isoColor--HeadingLight;
  }
}

.ant-select-dropdown-menu-item {
    color: @isoColor--HeadingLight;
}

.ant-select-tree li a {
  font-size: @isoFontSize - 1;
  color: @isoColor--HeadingLight;
}
