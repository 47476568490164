/* * * * * * * * * * * * * * * * * *
BUTTON
* * * * * * * * * * * * * * * * * */
@import '../../base.less';

.ant-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  line-height: 1.5;
  padding: 0 25px;
  font-size: @isoFontSize;
  border-radius: 4px;
  height: 36px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  color: @isoColor--HeadingLight;
  border-color: @isoColor--Border;
  .isoTransition;

  &:hover {
    border-color: @primary-color;
  }

  &.ant-btn-primary {
    background-color: @primary-color;
    border-color: @primary-color;

    &:hover {
      background-color: lighten(@primary-color, 8%);
      border-color: lighten(@primary-color, 8%);
    }
  }

  &.ant-btn-sm {
    padding: 0 15px;
    height: 28px;
    font-size: @isoFontSize - 2;
  }

  &.ant-btn-lg {
    padding: 0 35px;
    font-size: @isoFontSize;
    height: 42px;
  }

  &.ant-btn-primary {
    color: #ffffff;
  }

  &.ant-btn-dashed {
    border-style: dashed;
    border-color: @isoColor--BorderDark;

    &:hover {
      border-color: @primary-color;
    }
  }

  &.ant-btn-danger {
    background-color: @error-color;
    border-color: @error-color;
    color: #ffffff;

    &:hover {
      background-color: lighten(@error-color, 8%);
      border-color: lighten(@error-color, 8%);
    }

    &.ant-btn-background-ghost {
      color: @error-color;
      background-color: transparent;
      border-color: @error-color;

      &:hover {
        color: lighten(@error-color, 8%);
        border-color: lighten(@error-color, 8%);
      }
    }
  }

  &.ant-btn-circle,
  &.ant-btn-circle-outline {
    width: 35px;
    padding: 0;
    font-size: @isoFontSize;
    border-radius: 50%;
    height: 35px;

    &.ant-btn-sm {
      padding: 0;
      height: 28px;
      width: 28px;
      font-size: @isoFontSize - 2;
    }

    &.ant-btn-lg {
      padding: 0;
      font-size: @isoFontSize;
      height: 42px;
      width: 42px;
    }
  }
}

.ant-btn-clicked:after {
  border: 0 solid @yp-background-color;
}

// BTN Group
.ant-btn-group {
  .ant-btn {
    margin: 0;
    margin-right: 0;
  }

  &.ant-btn-group-lg {
    > .ant-btn {
      padding: 0 35px;
      font-size: @isoFontSize;
      height: 42px;
    }
  }

  &.ant-btn-group-sm {
    > .ant-btn {
      padding: 0 15px;
      height: 28px;
      font-size: @isoFontSize - 2;
    }
  }
}

// GHOST Button
.ant-btn-background-ghost {
  background: transparent !important;
  border-color: #fff;
  color: #fff;

  &.ant-btn-primary {
    color: @primary-color;
    background-color: transparent;
    border-color: @primary-color;
  }
}

.ant-btn:focus, .ant-btn:hover {
  color: @isoColor--Black !important;
}

// DISABLED BTN
.ant-btn.disabled,
.ant-btn[disabled],
.ant-btn.disabled:hover,
.ant-btn[disabled]:hover,
.ant-btn.disabled:focus,
.ant-btn[disabled]:focus,
.ant-btn.disabled:active,
.ant-btn[disabled]:active,
.ant-btn.disabled.active,
.ant-btn[disabled].active {
  color: @isoColor--Grey;
  background-color: #f7f7f7;
  border-color: @isoColor--Border;
}

.isoButton {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 0;
  white-space: nowrap;
  line-height: 1.5;
  padding: 0 25px;
  font-size: @isoFontSize - 1;
  border-radius: 4px;
  height: 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  color: #ffffff;
  background-color: @primary-color;
  .isoTransition;

  &:hover {
    background-color: darken(@primary-color, 5%);
  }

  &.isoBtnSm {
    padding: 0 15px;
    height: 28px;
    font-size: @isoFontSize - 2;
  }

  &.isoBtnLg {
    padding: 0 35px;
    font-size: @isoFontSize;
    height: 42px;
  }
}
