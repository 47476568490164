/* * * * * * * * * * * * * * * * * * * * *
MODAL
* * * * * * * * * * * * * * * * * * * * */
@import '../../base.less';

.ant-modal-header {
  padding: 13px 16px;
  border-radius: 4px 4px 0 0;
  background: @isoColor--DarkWhite;
  color: @isoColor--Heading;
  border-bottom: 1px solid @isoColor--Border;
}

.ant-modal-title {
  margin: 0;
  font-size: @isoFontSize + 1;
  line-height: 21px;
  font-weight: 500;
  color: @isoColor--Heading;
}

.ant-modal-body {
  padding: 16px;
  font-size: @isoFontSize - 1;
  color: @isoColor--TextDark;
  line-height: 1.5;
}

.ant-modal-footer {
  border-top: 1px solid @isoColor--Border;
  padding: 10px 16px 10px 10px;
  text-align: right;
  border-radius: 0 0 4px 4px;
}

.ant-confirm {
  .ant-modal-body {
    padding: 30px 35px;
  }
}

.ant-confirm-body {
  .ant-confirm-title {
    color: @isoColor--Heading;
    font-weight: 700;
    font-size: @isoFontSize + 1;
  }

  .ant-confirm-content {
    margin-left: 42px;
    font-size: @isoFontSize - 1;
    color: @isoColor--TextDark;
    margin-top: 8px;
  }
}
