.isoContentLoader {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .loaderElement {
    height: 3em;
    width: 3em;
    animation: rotation 1s infinite linear;
    border: 2px solid #FFDD00;
    border-top-color: rgba(255, 221, 0, 0.623);
    border-radius: 50%;
  }
}

@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}
