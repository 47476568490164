html[dir="rtl"] {
  .ant-input-affix-wrapper {
    .ant-input:not(:last-child) {
      padding-left: 0;
      padding-right: 24px;
    }

    .ant-input-suffix {
      left: auto;
      right: 7px;
    }
  }
}
