/* * * * * * * * * * * * * * * * * *
INPUT
* * * * * * * * * * * * * * * * * */
@import '../../base.less';

label {
  font-size: @isoFontSize - 1;
  color: @isoColor--HeadingLight;
}

.ant-form-item-label label {
  color: @isoColor--HeadingLight;
}

// Input
.ant-input {
  padding: 4px 10px;
  width: 100%;
  height: 35px;
  cursor: text;
  font-size: @isoFontSize - 1;
  line-height: 1.5;
  color: @isoColor--HeadingLight;
  background-color: #fff;
  background-image: none;
  border: 1px solid @isoColor--Border;
  border-radius: 4px;
  .isoTransition;

  &.ant-input-lg {
    height: 42px;
    padding: 6px 10px;
  }

  &.ant-input-sm {
    padding: 1px 10px;
    height: 30px;
  }

  &::-webkit-input-placeholder {
    color: @isoColor--GreyShade;
  }

  &:-moz-placeholder {
    color: @isoColor--GreyShade;
  }

  &::-moz-placeholder {
    color: @isoColor--GreyShade;
  }
  &:-ms-input-placeholder {
    color: @isoColor--GreyShade;
  }
}

.ant-input:focus {
  box-shadow: 0 0 0 2px rgba(255, 221, 0, 0.2)
}

.ant-input-group-lg {
  .ant-input,
  > .ant-input-group-addon {
    padding: 6px 10px;
    height: 35px;
  }
}

// Input Number
:root {
  .ant-input-number {
    color: @isoColor--HeadingLight;
    padding: 0;
    font-size: @isoFontSize - 1;
    height: 35px !important;
    border: 1px solid @isoColor--Border;
    width: 100%;
    max-width: 80px;

    .ant-input-number-input {
      height: 33px;
      color: @isoColor--HeadingLight;
      padding: 0 10px;
    }

    &.ant-input-number-focused {
      border-color: @primary-color;
    }

    .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-inner {
      font-size: @isoFontSize * 1.5 + 2;
      color: @isoColor--Text;

      &:before {
        display: block;
        font-family: "ionicons" !important;
      }
    }

    .ant-input-number-handler-up-inner {
      &:before {
        content: "\f365";
      }
    }

    .ant-input-number-handler-down-inner {
      &:before {
        content: "\f35f";
      }
    }
  }
}
// INPUT GROUP
.ant-input-group {
  margin-bottom: 10px;
  .ant-input-group-addon:not(:first-child):not(:last-child),
  .ant-input-group-wrap:not(:first-child):not(:last-child),
  > .ant-input:not(:first-child):not(:last-child) {
    padding: 0 7px;
  }

  .ant-input-group-addon {
    padding: 4px 7px;
    font-size: @isoFontSize - 2;
    color: @isoColor--HeadingLight;
    text-align: center;
    background-color: @isoColor--GreyLight;
    border: 1px solid @isoColor--Border;
    transition: all .3s;

    &:first-child {
      border-right: 0;
    }

    &:last-child {
      border-left: 0;
    }

    .ant-select {
      .ant-select-selection {
        background-color: inherit;
        margin: -1px;
        border: 1px solid transparent;
        box-shadow: none;
      }
    }
  }
}

// SEARCH
.ant-input-affix-wrapper {
  .ant-input-search-icon {
    color: @isoColor--GreyShade;

    &:hover {
      color: @primary-color;
    }
  }
}
