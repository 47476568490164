/* * * * * * * * * * * * * * * * * *
Common Style
* * * * * * * * * * * * * * * * * */
.display--grid{
  display: grid
} 
.justify-content--space-between{
  justify-content: space-between;
}
.justify-content--space-around{
  justify-content: space-around;
}
.display--flex {
  display: flex;
}
.align-items--center{
  align-items: center;
}

.flex-xs-row-sm-col{
  display: flex;
}
.flex-md-row-sm-row{
  display: flex;
}
.width-xs-100-sm-50{
  width: 50%
}
.flex-grow--1{
  flex-grow: 1;
}

.height44{
  height: 44px;
}

.height--inherit{
  height: inherit;
}

.height--100P{
  height: 100%;
}


.text-align--justify{
  text-align: justify;
}

.marginTop65{
  margin-top: 65px;
}

.marginTop40{
  margin-top: 40px;
}

.marginBtm40{
  margin-bottom: 40px;
}
.color--blue{
  color: blue;
}

.flex-row_rev-align{
  flex-direction: row-reverse;
}

.flex-center-content{
  justify-content: center;
}

.color--grey{
  color: grey
}

.color--crimson{
  color: crimson;
}

.fontSize17{
  font-size: 17px;
}

@media screen and (max-width: 700px){
  .stakeholder--img{
    width: fit-content;
    margin: auto;
  }
  .flex-xs-row-sm-col{
    display: flex;
    flex-direction: column;
  }
  .width-xs-100-sm-50{
    width: 100%;
  }
}

@font-face {
  font-family:"CircularBold";
  src: url("../font-circular/fonts/CircularStd-Bold.ttf");
  font-weight: bold;
}


@font-face {
  font-family:"CircularMedium";
  src: url("../font-circular/fonts/CircularStd-Book.otf");
  font-weight: normal;
  color: #000;
}

::selection {
  background: @primary-color;
  color: #000;
}

.ant-radio-inner:after {
  background-color: @primary-color;
}

.ant-radio-checked .ant-radio-inner {
  border-color: @primary-color;
}

.ant-radio-checked:after {
  border: 1px solid @primary-color;

}

.error-color {
  color: @error-color;
}

html {

  //This will prevent IE from overlapping scrollbars on content
  -ms-overflow-style: scrollbar;
}

h1,
h2
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html,
html a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: @primary-color;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

body {
  font-family: @isoFontFamily;
}

a:hover {
  color: @isoColor--Orange;
}

.white-space--line {
  white-space: pre-line;
}

.overflow-wrap--word {
  overflow-wrap: break-word;
}

.overflow--auto {
  overflow: auto;
}

.overflow--hidden {
  overflow: hidden;
}

.img-height-full {
  min-width: 100%;
  height: auto;
}

.flex-column-align {
  display: flex;
  flex-direction: column;
}

.flex-row-align {
  display: flex;
  flex-direction: row;
}

.ant-select, .ant-input, .form-control {
  color: #000!important;
}

.nav-bottom--home{
  padding-bottom: 8px;
}

.isomorphicLayout {
  width: calc(~"100% - 240px");
  flex-shrink: 0;
  overflow-x: hidden !important;

  @media only screen and (max-width: 767px) {
    width: 100%;
    
  }

  @media only screen and (min-width: 768px) and (max-width: 1220px) {
    width: calc(~"100% - 64px");

  }
}

.padding-prevent-margin-collapse {
  padding: 1px 0px;
}

.width500{
  width: 500px !important;
  max-width: 80% !important;
}

.isoContentLoaderContact{
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  .loaderElement {
    height: 3em;
    width: 3em;
    animation: rotation 1s infinite linear;
    border: 2px solid #FFDD00;
    border-top-color: rgba(255, 221, 0, 0.623);
    border-radius: 50%;
  }
}
@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}
.select-error .ant-form-explain{
  display: none;
}
.slider-control-centerleft{
  display: none !important;
}
.slider-control-centerright{
  display: none !important;
}
.slider-control-bottomcenter{
  display: none !important;
}

//LANDING PAGE CSS STARTS

.home-page--container {
  background: #212123;
  position: relative;

  //Helps IE calculate the correct height
  min-height: 1px;
}

.home-page--content-container {
  display: grid;
  display: -ms-grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto auto 1fr auto;
  -ms-grid-rows: auto 1fr;
  -ms-grid-columns: auto auto 1fr auto;
  padding-top: 14rem;
  padding-bottom: 4rem;

  //Fixes firefox aligning content at the bottom
  align-items: start;
}

.side-bar--container-home {
  background: #212123;
  min-width: 200px;
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 2;
  grid-row: 2;
  width: 300px;
  // width: 15vw;
}

.side-bar--mobile-container {
  display: flex;
  justify-content: center;
}

.side-bar--mobile-content {
  padding: 2rem 0px;
  display: flex;
  flex-direction: row;
  background: #212123;
  overflow: auto;
}

.side-bar--padding{
  padding-top: 0px;
  padding-bottom: 15%;
}

.side-bar--options{
  margin: 0px 10px;
}

.booking-form--home-container {
  grid-row: 2;
  -ms-grid-row: 2;
  grid-column: 2;
  -ms-grid-column: 2;
}

.booking-form--text_description-container {
  grid-column: 2;
  -ms-grid-column: 2;
  grid-row: 1;
  -ms-grid-row: 1;
}

.booking-form--home {
  width: 500px;
  max-width: 90vw;
}

.gif-container--home {
  grid-row-start: 1;
  grid-row-end: 3;
  -ms-grid-row-span: 3;
  grid-column: 3;
  -ms-grid-column: 3;
  margin: 0px -8% 0px -18%;

  /*
    All grid items are aligned to start
    However, this image must be center aligned as the screen resizes and
    the image resizes, hence add this property.
  */
  align-self: center;
  -ms-grid-row-align: center;
}

.gif-container--image {
  max-width: 100%;
}

.booking-form--text_description {
  letter-spacing: 1.25px;
  white-space: nowrap;

  //In IE flex grow overlaps on this content, but if position is relative then this content is shown otherwise it would be clipped
  position: relative;
}

.booking-form--text_description > :first-child {
  font-size: 31px;
}

.booking-form--text_description > :last-child {
  font-size: 1.8rem;
  opacity: 0.8;
}

.socials--home-container {
  -ms-grid-column: 4;
  grid-column: 4;
  -ms-grid-row: 2;
  grid-row: 2;
}
// @media screen and (min-width: 1300px){
//   .home-page--content-container{
//     background-image: url(../image/main/3.png);
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: center;
//     padding-top: 20vh;
//     padding-bottom: 20vh;
//     margin: 0px 0px;
//     height: calc(100vh - 60px);
//   }
// }
// This media screen is length is also being used in the home container to prevent the image from being loaded on mobile to improve initial load time
@media screen and (max-width: 1300px) {
  .gif-container--home {
    display: none;
  }

  .socials--home-container {
    -ms-grid-column: 3;
    grid-column: 3;
  }

  .home-page--content-container {
    grid-template-columns: 30% 1fr auto;
    -ms-grid-columns: 30% 1fr auto;
    // background-color: #212123;
  }
}

.help--sub-section-allignment{
  text-align: right;
}

//The ant framework breaks on min-width 992 hence max-width with will have to be one less
@media (max-width: 991px) {
  .home-page--content-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 6rem;
    padding-bottom: 4rem;
    background-color: #212123;
  }

  .side-bar--container-home {
    display: none;
  }

  .booking-form--text_description-container {
    //2rem is already added by the top bar. 2 more here would equal the bottom margin of 4rem
    margin-top: 2rem;
  }

  .booking-form--text_description {
    white-space: normal;
    text-align: center;
  }
  
  .booking-form--text_description > :first-child {
    font-size: 1.8rem;
  }
  
  .booking-form--text_description > :last-child {
    font-size: 1.1rem;
  }

  .socials--home-container {

    //Mimics the width of the booking-form--home css. TODO use variables to ensure code remains compliant
    width: 500px;
    max-width: 90vw;

    //Matches the margin above and beneath the booking page
    // margin-top: 4rem;
    margin: auto;
  }

  .socials--home-container > .socials {
    display: flex;
    flex-direction: row;
  }

  .socials--home-container > .socials > * {
    flex-grow: 1;
  }

  .sm-help-links a{
    line-height: 30px;
  }

  .help--sub-section-allignment{
    text-align: center;
  }
}

@media (max-width: 991px) {
  .home-page--content-container{
    padding-bottom: 1rem;
  }
  .booking-form--text_description-container{
    margin-top: 1.2rem;
  }
}

//LANDING PAGE CSS ENDS

.mobilebar-opt{
  color:#fff !important;
  line-height: 20px;
}

.mobilebar-active{
  color:#000 !important;
  background: #ffdd00;
  line-height: 20px;
  border-radius: 8px; 
  padding: 5px 0px;
}

.custom-scrollbar{
  overflow-y: auto;
  scrollbar-color: rgba(0, 0, 0, 0.4) rgba(0,0,0, 0.1);
  scrollbar-width: thin;
}

.custom-scrollbar::-webkit-scrollbar{
  cursor: pointer;
  border-radius: 4px;
  width: 8px;
  background: rgba(0, 0, 0, 0.1);
}
.custom-scrollbar::-webkit-scrollbar-track{
  cursor: pointer;
  border-radius: 4px;
  width: 8px;
  background: rgba(0, 0, 0, 0.1);
}
.custom-scrollbar::-webkit-scrollbar-thumb{
  cursor: pointer;
  border-radius: 4px;
  width: 8px;
  background: rgba(0, 0, 0, 0.4);
}

@media (max-width: 1130px) {
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse{
    max-height: 500px;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-collapse.collapse.in{
    display: block !important;
  }

  .navbar-nav {
    float: none!important;
    margin: 7.5px 0px;
  }
  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.txt-transform{
  text-transform: capitalize;
}

.wd-100{
  width: 100% !important; 
}

.t&c{
  position: absolute;
  background: wheat;
  margin-top: 20px;
}

.ant-input-number .ant-input-number-handler-wrap{
  display: none !important;
}

.socials--absolute-container {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.socials .ant-row{
  width: 42px;
  padding: 10px 5px;
  background: #383838;
  margin-bottom: 1px;
  display: flex;
  justify-content: center;
}

.socials .ant-row:last-child {
  padding: 2px;
}

@media screen and (max-width: 600px){
  .socials{
    display: none;
  }
}

.socials .ant-row img{
  margin-top: 7px;
}

.ant-layout-sider-collapsed .anticon {
  font-size: 16px;
}

.ant-layout-sider-collapsed .nav-text {
  display: none;
}

.ant-layout {
  background: #f1f3f6;
  overflow: auto;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: @primary-color;
  border-color: @primary-color;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: @primary-color;
}

.ant-menu-vertical {
  border-right: 1px solid #e9e9e9;
}
.ant-menu-vertical .ant-menu-item {
  border-right: 1px solid #e9e9e9;
}

.ant-popover .ant-popover-inner .ant-popover-inner-content a {
  text-decoration: none;
}

.isoLayoutContentWrapper {
  padding: 50px 15px;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;

  @media only screen and (max-width: 767px) {
    padding: 50px 20px;
    
  }

  .isoComponentTitle {
    font-size: @isoFontSize + 5;
    font-weight: 500;
    color: @isoColor--DarkBlue;
    width: 100%;
    margin: 0 17px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    white-space: nowrap;

    @media only screen and (max-width: 767px) {
      margin: 0 10px;
      margin-bottom: 30px;
    }

    &:before {
      content: '';
      width: 5px;
      height: 40px;
      background-color: darken(@isoColor--LightBluish, 5%);
      display: flex;
      margin-right: 15px;
    }

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: darken(@isoColor--LightBluish, 5%);
      display: flex;
      margin-left: 15px;
    }
  }

  .isoBoxWrapper {
    width: 100%;
    height: 100%;
    // padding: 25px 30px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid @isoColor--Border;
    margin: 0 17px 30px;

    &:last-child {
      margin-bottom: 0;
    }

    @media only screen and (max-width: 767px) {
      padding: 20px;
      margin: 0 10px 30px;
    }

    &.half {
      width: calc(~"50% - 34px");
      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .isoBoxTitle {
      font-size: @isoFontSize;
      font-weight: 500;
      color: @isoColor--Heading;
      margin: 0;
      margin-bottom: 5px;
    }

    .isoBoxSubTitle {
      font-size: @isoFontSize - 1;
      font-weight: 400;
      color: @isoColor--TextDark;
      line-height: 24px;
    }

    code {
      font-size: @isoFontSize - 1;
      font-weight: 400;
      color: @isoColor--HeadingLight;
      padding: 2px 7px;
      background-color: @isoColor--DarkerWhite;
      border: 1px solid @isoColor--Border;
      .isoBorderRadius(3px);
    }

    .isoExampleWrapper {
      margin-top: 30px;
      -webkit-overflow-scrolling: touch;

      .demoBtn {
        margin-right: 10px;
      }

      .demoPosBtn {
        width: 70px;
        margin-right: 8px;
        margin-bottom: 8px;
        padding: 0;

        @media only screen and (max-width: 480px) {
          width: 65px;
     
        }
      }

      .demoBtnsWrapper {
        @media only screen and (max-width: 480px) {
          margin-left: 0 !important;
          white-space: nowrap;
          margin-right: 8px;
          float: none !important;
        }
      }

      .ant-btn {
        // margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }

      .tooltipBtn {
        display: inline-block;
        line-height: 32px;
        height: 32px;
        width: 70px;
        font-size: 14px;
        text-align: center;
        background: @isoColor--LightBluish;
        margin-right: 6px;
        margin-bottom: 6px;
        border-radius: 6px;
      }

      .ant-progress {
        &.ant-progress-circle {
          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      strong {
        font-weight: 700;
        font-size: @isoFontSize;
      }
    }
  }
}
@media (max-width: 580px) {
  .isoLayoutContentWrapper {
    padding: 15px;
  }
}

.isoFormFieldTitle {
  font-size: @isoFontSize;
  font-weight: 700;
  color: @isoColor--HeadingLight;
  margin: 0 0 10px;
  display: block;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.list--circle {
  list-style-type:circle;
}

.ant-layout-footer {
  padding: 10px 50px !important;
  font-size: @isoFontSize - 1;
  @media (max-width: 767px) {
    padding: 10px 20px;
  }
}

.isoEmptyComponent {
  min-height: 74vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: @isoFontSize * 2 + 8;
    font-weight: 300;
    color: @isoColor--GreyDark;
    line-height: 1.5;
  }
}

.customSidebar {
  background-color:#212123;
  a {
    color: #fff;
    text-decoration: none;
    letter-spacing: 1px;
    font-size: 12.5px;
  }

}

.color-green {
  color: #7CD47C;
}

.color-orange {
  color: #EFCF03;
}

.customSidebarNew {
  background-color: #060611;
  
  padding-top: 100px !important;
  // height: 100vh;
  a {
    color: #fff;
    text-decoration: none;
    letter-spacing: 1px;
    font-size: 12.5px;
  }
}

.marginTop0 {
  margin-top: 0px!important;
}

.marginLeft0 {
  margin-left: 0px!important;
}

.marginLeft10 {
  margin-left: 10px!important;
}

.marginRight10 {
  margin-right: 10px !important;
}

.marginBtm5 {
  margin-bottom: 5px !important;
}

.staticBackground {
  background: url(../image/main/background2.jpg) no-repeat center center;
  background-size: cover;
  height: 100%;
  background-color: #000000;
  opacity: 0.9;
}

.box-shadow-common {
 box-shadow: 0 0 2px 2px rgba(0,0,0,0.07); 
}

.center-img {
  display: block;
  margin: 0 auto;
}

.booking-pad10{
  padding: 0px;
}

@media screen and (max-width : 767px){
  .booking-pad10{
    padding: 0px 10px;
  }
}

.hidden-770{
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 990px){
  .hidden-770{
    display: block;
  }
}

.booking-form {
  
  background: #f8f8f8;
  // z-index: 9;
  box-shadow: 0 0 8px 8px rgba(0,0,0,0.07);
  .ant-form-horizontal {
    
    .ant-form-item {
      margin-bottom: 0px;
      .ant-input-group-addon {
        background: #fff;
        min-width: 95px;
        text-align: left;
        font-size: 15px;
      }
      .ant-input {
          background-color: #fff;
          font-size: 15px;
      }
      .ant-input-number{
        background-color: #fff;
          font-size: 15px;
      }
      .ant-radio-wrapper {
        font-size: 15px;
      }
      .ant-radio-group {
        padding-top: 10px!important;
      }
    }
  }
}

.isoRight li:last-child {
  margin-right: 35px!important;
}

.mainPageText {
  position: absolute;
  // top: 100px;
  color: #fff;
  // z-index: 9;
  height: 200px;
  letter-spacing: 1.25px;
}

.mainPageTextMobile {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  padding: 15px!important;
}

.font-size12 {
  font-size: 12px!important;
}

.mobile-background {
  background-color: #060611;
  opacity: 0.9;
}

.mobile-app-wrapper {
  background-color: #f8f8f9;
  .ant-form-item {
    margin-bottom: 0px !important;
  }
  .pointer {
    min-height: 55px!important;
  }

  .ant-input-group-addon {
      min-width: 85px;
  }
}

.form-wrapper {
  padding: 10px;
}

.search-button {
  width: 100%;
  height: 45px;
  background-color: #FFDD00!important;
  color: #000000 !important;
  border: none!important;
  font-weight: 700 !important;
  letter-spacing: 1px;
}

.booking-form.searchcabs {
    top: 20px !important;
    min-height: 400px;
    .ant-form-horizontal {
      padding-top: 10px!important;
      padding-bottom: 10px;
    }
    .ant-form-item {
      margin-bottom: 0px !important;
    }
    .available-cars {
      background: #fff;
      padding-top: 15px!important;
      .ant-input {
        height: 55px!important;
      }
      .ant-input-number{
        height: 55px !important;
      }
    }
  }

  .cancel-button {
    font-size: 16px;
    color: #fe6559;
    text-align: center;
    width: 100%;
  }

.mybooking {
  width: 80%;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  .ant-tabs-content {
    margin: 0 20px;
  }
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #FFDD00!important;

  }

.ant-tabs-tab:hover {
  color: #FFDD00!important;

}

.bg-grey-md {
  background-color: #F2F2F2!important;
}

.ant-input-group .ant-input-group-addon {
  background-color: #F2F2F2!important;
  
}

.custom-date-time-wrapper {
  .custom-position {
    position: relative;
    .ant-col-9 {

      position: absolute;
      top:0;
      left:0;
    }

    .ant-col-15 {
            position: absolute;
          top:0;
          right:0;
    }
    
    
  }

  .ant-calendar-picker-input {
    height: 38px!important;
    border: none;
    border-right: 1px solid #e9e9e9;
    padding-left: 0px;
    border-radius: 0;
  }
  .ant-time-picker {
    width: 100%;
  }
  .ant-time-picker-input {
    height: 32px;
    border: none;
  }
}

.paddingTop30 {
  padding-top: 30px;
}

.paddingBtm30 {
  padding-bottom: 30px;
}

.font-size11 {
  font-size: 11px;
}

.fS14 {
  font-size: 1.4rem;
}
.fS17e {
  font-size: 1.7rem;
}
.fS15 {
  font-size: 1.5rem!important;
}
.fS16 {
  font-size: 1.6rem;
}
.fS13 {
  font-size: 1.3rem;
}
.fS17 {
  font-size: 1.1em;
}
.fS18e {
  font-size: 1.8rem;
}
.fS54 {
  font-size: 5.4rem;
}
.fS31 {
  font-size: 31px;
}
.fS18 {
  font-size: 1.4rem;
}

.fS42 {
  font-size: 4.2rem;
  @media screen and (min-width:990px) and (max-width:1280px) {
    font-size: 4.0rem;
  }
}
.fS24 {
  font-size: 2.4rem;
}

@media screen and (min-width:1281px) and (max-width:1365px) {
  .fS15 {
    font-size: 1.5rem;
  }
  .fS16 {
    font-size: 1.6rem;
  }
  .fS13 {
    font-size: 1.3rem;
  }
  .fS17 {
    font-size: 1.7rem;
  }
  .fS18e {
    font-size: 1.8rem;
  }
  .fS54 {
    font-size: 4.2rem;
  }
  .fS31 {
    font-size: 31px;
  }
  .fS18 {
    font-size: 1.3rem;
  }
  .fS42 {
    font-size: 4.2rem;
  }
  .fS24 {
    font-size: 2.4rem;
  }
}

@media screen and (min-width:990px) and (max-width:1280px) {
  .navbar-nav > li > a {
    font-size: 10.5px!important;
    margin: 15px 2px!important;
  }
  .navBottom-color {
    font-size: 9.5px;
  }
  .fS15 {
    font-size: 1.3rem;
  }
  .fS16 {
    font-size: 1.4rem;
  }
  .fS13 {
    font-size: 1.1rem;
  }
  .fS17 {
    font-size: 1.5rem;
  }
  .fS18e {
    font-size: 1.6rem;
  }
  .fS54 {
    font-size: 4.0rem;
  }
  .fS31 {
    font-size: 29px;
  }
  .fS18 {
    font-size: 1.1rem;
  }
  .fS42 {
    font-size: 4.0rem;
  }
  .fS24 {
    font-size: 2.2rem;
  }
  .fS17e {
    font-size: 1.6rem;
  }
}

.gradient {
  background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.8))
}

.fwb {
  font-weight: bold;
}

.fwn {
  font-weight: normal;
}

.right-align {
  float: right;
}

.left-align {
  float: left;
}

.height45 {
  height: 45px!important;
}

.clearFloat {
  float: none;
  clear: both;
}

.height140 {
  height: 140px!important;
}

.height44 {
  height: 44px!important;
}

.height55 {
  height: 55px!important;
}

.height330 {
  height: 330px!important;
}

.bg-brand {
  background-color: #F0F1EC;
}

.height340 {
  height: 340px!important;
}

.height360 {
  height: 360px!important;
}

.height400 {
  height: 400px!important;
}

.height450 {
  height: 450px!important;
}

.height260 {
  height: 260px!important;
}

.height280 {
  height: 280px!important;
}
.top15 {
  top: 15px;
}

.position-abs {
  position: absolute!important;
}

.car-details-radio-pos {
  top: calc(50% - 17px);
}

.btm-5 {
  bottom: 5px;
}

.top-10 {
  top: 10px;
}

.position-abs-price {
  position: absolute;
  right: 10px;
  top: 15px;
}

.mobile-topbar-task {
  background: #fff;
  border-bottom: 1px solid #e5e5e4;
  margin-bottom: 10px;
  padding: 10px;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center!important;
}

.user-confirm {
  padding: 0 10px!important;
  img {
    width: 40px;
  height: 40px;
  }

}

.pickup-done {
color: #2bac2b;
}

.mobile-wrapper-2 {
  background-color: #fff!important;
}

.truncate {
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
}

.truncate-mobile {
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 30%;
}

.truncate--available_car {
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

@media only screen and (max-width: 768px) {
  .truncate--available_car {
    white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 50%;
  }
}



//TODO once, the car details placeholder component is redesigned this will probably not be needed, hardcoded limit for now
.car-details--model {
  max-width: 150px;
  width: 100%;
}

.navbar-toggle {
  padding: 15px;
  float: left;
  background: #191919;
  margin-top: 10px;
  margin-right: 10px;
}

.top-bar--container > :first-child {

  //Same as the max-width given to the content
  max-width: 2000px;
  margin: 0 auto;
  width: 100%;
}

.navbar {
  min-height: 65px;
  border-radius: 0;
  .container, .navbar-header, navbar-nav {
  }
  .container {
    margin: 0!important;
    width: 100%!important;
    max-width: 100%!important;
  }
}

.navbar-brand {
  line-height: 65px;
}

.navbar-nav > li > a{

    line-height: 32.5px!important;
    color: #fff!important;
    font-size: 12px;
    padding: 0px 10px!important;
    margin: 15px 5px;
    letter-spacing: 2px;
}

.login-border > a {
  border: 1px solid #fff;
  border-radius: 2px;
}

.navbar-inverse {
  background: #212123;
  border-color: #212123;
}

.navbar-brand {
  margin-left: 10%!important;
  padding: 0;
  img {
    height: 50px;
  }
}

.navbar-right {
  margin-right: 2%;
}

@media only screen and (min-width: 768px) and (max-width: 1220px) {
  navbar {
    height: 65px;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-brand {
    margin-left: 0%!important;
  }
}

.navbar-img {
  height: 55px;
}

@media only scren and (min-width: 1220px) and (max-width: 1680px) {
  .navbar-inverse .navbar-collapse, .container>.navbar-collapse {
    margin-right: -40%!important;
  }
  
}

@media only scren and (min-width: 1680px) and (max-width: 3000px) {
  .navbar-collapse .collapse {
    margin-right: -60%!important;
  }
}

.mapMarker {
  background-image: url(../image/main/iconSet.png);
  background-position: -15px;
  position: absolute;
  width: 25px;
  height: 25px;
  top: ~"calc(50% - 12.5px)";
  left: ~"calc(50% - 12.5px)";
}

.mybooking-location {
  font-family: 'CircularMedium';
  font-size: 15px;
  padding-top: 10px;
}

.loc-dot {
  background-image: url(../image/main/iconSet.png);
  background-position: -15px;
  width: 25px;
  height: 25px;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-top {
  vertical-align: top;
}

.box-shadow-none {
  box-shadow: none;
}

.cancel-icon {
  width: 25px;
    height: 35px;
    display: inline-block;
    vertical-align: bottom;
    background-image: url(../image/main/iconSet.png);
    background-position-x: -90px;
    background-position-y: 340px;
}

#pickupIcon {
    width: 25px;
    height: 35px;
    background-image: url(../image/main/iconSet.png);
    background-position-x: -10px;
    background-position-y: 192px;
    position: absolute;
    right: 0;
    margin-right: 5px;
    cursor: pointer;
}

#rentalIcon {
    width: 25px;
    height: 35px;
    background-image: url(../image/main/iconSet.png);
    background-position-x: -10px;
    background-position-y: 188px;
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
    cursor: pointer;
}

.span-align {
  display: inline-block;
  vertical-align: top;
}

 .ant-input-addon,.ant-input,.ant-input-number {
  height: 44px!important;
 }

 .borderBtm-common {
    border-bottom: 1px solid #e5e5e4!important;
 }

 .border-none {
  border: none!important;
 }


.luxurysedan-base{
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/luxurysedan.png);
    background-repeat: no-repeat;
}

.luxurysedan-mid{
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/luxurysedan.png);
    background-repeat: no-repeat;
}

.luxurysedan-high{
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/luxurysedan.png);
    background-repeat: no-repeat;
}s

.luxurysedan-base_active{
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/luxurysedan_active.png);
    background-repeat: no-repeat;
}

.luxurysedan-mid_active{
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/luxurysedan_active.png);
    background-repeat: no-repeat;
}

.luxurysedan-high_ative{
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/luxurysedan_active.png);
    background-repeat: no-repeat;
}





 .sedan {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/sedan.png);
    background-repeat: no-repeat;
}

 .sedan_active {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/sedan_active.png);
    background-repeat: no-repeat;
}

.luxury {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/luxury.png);
    background-repeat: no-repeat;
}


.luxury_active {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/luxury_active.png);
    background-repeat: no-repeat;
}

.luxurysedan {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/luxurysedan.png);
    background-repeat: no-repeat;
}


.luxurysedan_active {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/luxurysedan_active.png);
    background-repeat: no-repeat;
}
.luxurysuv {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/luxurysuv.png);
    background-repeat: no-repeat;
}


.luxurysuv_active {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/luxurysuv_active.png);
    background-repeat: no-repeat;
}

.premiumsedan {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    background-image: url(../image/main/premiumsedan.png);
    background-repeat: no-repeat;
}

.premiumsedan_active {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    background-image: url(../image/main/premiumsedan_active.png);
    background-repeat: no-repeat;
}
.executivesedan {
    width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/executivesedan.png);
    background-repeat: no-repeat;
}
.executivesedan_active {
    width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/executivesedan_active.png);
    background-repeat: no-repeat;
}
.suv {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/suv.png);
    background-repeat: no-repeat;
}
.suv_active {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/suv_active.png);
    background-repeat: no-repeat;
}
.premiumsuv {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/premiumsuv.png);
    background-repeat: no-repeat;
}
.premiumsuv_active {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/premiumsuv_active.png);
    background-repeat: no-repeat;
}
.executivesuv {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/executivesuv.png);
    background-repeat: no-repeat;
}
.executivesuv_active {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/executivesuv_active.png);
    background-repeat: no-repeat;
}
.hatchback {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/executivesuv.png);
    background-repeat: no-repeat;
}
.hatchback_active {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/executivesuv_active.png);
    background-repeat: no-repeat;
}

.tempotraveller-_16_1seater_{
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/tempotraveller-_16_1seater_.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.tempotraveller-_16_1seater__active {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/tempotraveller-_16_1seater__active.png);
    background-repeat: no-repeat;
    background-size: contain;
}

.tempotraveller-_12_1seater_{
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/tempotraveller-_12_1seater_.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.tempotraveller-_12_1seater__active {
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/tempotraveller-_12_1seater__active.png);
    background-repeat: no-repeat;
    background-size: contain;
}

[class^='traveller_']{
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/tempo_traveller-inactive.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

[class^='traveller_'][class*='_active']{
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/tempo_traveller_active.png);
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 100%;
}



.seater_active{
  width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../image/main/tempo_traveller_active.png);
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 100%;
}

.font-size16 {
  font-size: 16px;
}

.font-size20 {
  font-size: 20px;
}

.pointer {
  cursor: pointer!important;
}

.cursor--not_allowed {
  cursor: not-allowed;
}

.ant-input-number {

    height: 44px!important;
    border-bottom-left-radius: 0!important;
    border-top-left-radius: 0!important;
    /* border-left: none; */
    margin-top: 0!important;
    border-color: #e9e9e9!important;

}

.ant-input-group-addon {
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
}

.ant-input-number-focused {
  box-shadow: none!important;
}

.paddingTop20 {
  padding-top: 20px!important
}

.pos-relative {
  position: relative;
}

.color-Black {
  color:#000;
}

.color-Red {
  color:#f04134;
}

.custom-validation {
  color:#f04134;
  display:block;
  font-size:12px;
  line-height: 1;
}

.custom-error {
  border-color: #f04134!important;
}

.paddingLeft25 {
  padding-left: 25px!important;
}

.paddingLeft5 {
  padding-left: 5px!important;
}

.paddingTop5 {
  padding-top: 5px!important;
}

.paddingBottom5 {
  padding-bottom: 5px!important;
}

.fontSize20 {
  font-size: 20px;
}

.fontSize14 {
  font-size: 14px;
}

.fontSize15 {
  font-size: 15px;
}

.bookingList .ant-radio-button-wrapper {
    width: 33%;
}

.ant-radio-button-wrapper {
    text-align: center;
    font-size: 14px;
    height: 35px!important;
    line-height: 33px!important;
    color: #000!important;
    font-weight: 100;
    box-shadow: none!important;
}

.ant-radio-button-wrapper-checked {
  border-color: #000!important;
  background-color: #000!important;
  color: #FFDD00!important;
}


.long-term .ant-radio-group {
  width: 100%;
  padding-top: 0!important;
  padding-bottom: 10px!important;
}

.pac-container {
  font-family: 'CircularBold';
  z-index: 9999;
}

.color-Orange {
  color: #FFDD00;
}


.padding20 {
  padding: 20px;
}

.padding25 {
  padding: 25px;
}

.left120 {
  left: 120px;
}

.btm-10 {
  bottom: 10px;
}

.btm-20 {
  bottom: 20px;
}

.left-30 {
  left: 30px
}

.long-term-review {
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.scrollable {
  overflow-y: auto;
  max-height: 350px;
}

.scrollable-mybooking {
  overflow-y: auto;
  height: 380px;
}

.scrollable-booking {
  overflow-y: auto;
  height: 150px;
}

.sidebar-icon {
  background: url(../image/main/dash_white.jpg) no-repeat center center;
  padding-left: 20px;
  background-position-x: left;
}

.sidebar-active {
  .sidebar-icon {
    background: url(../image/main/dash_orange.jpg) no-repeat center center;
    background-position-x: left;
    color: #FFDD00;
    text-decoration: none;
    background-size: 40px 2px;
  }
}

.sidebar-opt:hover .sidebar-icon {
  background: url(../image/main/dash_orange.jpg) no-repeat center center;
  padding-left: 20px;
  background-position-x: left;
  color: #FFDD00;
    text-decoration: none;
}

vertical-align-baseline {
  vertical-align: baseline;
}

.paddingLeft35 {
  padding-left: 35px;
}

.sidebar-opt {
  padding-left: 10%;
}

.validation-error {
  border: 1px solid #f04134!important;
}

.line-height-manager {
  line-height: 2.5!important;
}

.width95p {
  width: 95%!important;
}

.margin10 {
  margin: 10px!important;
}

.margin5 {
  margin: 5px!important;
}

.padding10 {
  padding: 10px!important;
}

.marginBtm20 {
  margin-bottom: 20px!important;
}

.ant-input:focus, .ant-input:hover, .ant-calendar-picker-input:not(.ant-input-disabled), .ant-select .ant-select-selection:hover, .ant-select .ant-select-open .ant-select-selection {
  border-left-color: #e9e9e9!important;
  border-right-color: #e9e9e9!important;
  border-top-color: #e9e9e9!important;
  border-bottom-color: #e9e9e9!important;
  box-shadow: none;
}

.ant-select .ant-select-selection {
  border-top-left-radius: 0px!important;
  border-bottom-left-radius: 0px!important;
}

.ant-select .ant-select-selection.ant-select-selection--single {
  height: 44px!important;
  font-size: 15px;
}


.ant-select .ant-select-selection .ant-select-selection__rendered {
  height: 44px!important;
  line-height: 3!important;
}

.width100P {
  width: 100%;
} 

form .ant-input-group .ant-select {
  width: 100%!important;
  height: 44px;

}

.ant-select-dropdown-menu-item {
  font-size: 15px;
}

.isoLandingPageN {
  overflow-x: hidden;
  .navbar {
    width: 100%;
    background: transparent;
    position: absolute;
    top: 0px;
    border: none;
    z-index: 9;
  }
}

.font36 {
  font-size: 36px;
}

.font18 {
  font-size: 18px;
}

.top10p {
  top:10%;
}

.wordBreakInherit {
  word-break: inherit!important;
}

.paddingRight120 {
  padding-right: 120px;
}

.navBottom-child {
  padding: 15px 10px;
  border: 1px solid transparent;
  font-size: 12px;
  background-color: #000000;
  margin: 0 2px;
  flex-grow: 1;
}

.navBottom-sub-parent {
  display: flex;
  background: #212123;
}

.navBottom-color {
  color: lightgrey;
  opacity: 1;
  padding-left: 5px;
  vertical-align: middle;
}

.navBottom-icon {
  margin-right: 2px;
}
.navBottom-icon img{
  width: 29px;
  height: 29px;
}

.navBottom-book {
  display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
}

.signup-l {
  width: 25%;
  background: #fff;
  opacity: 0.8;
  border-radius: 5px;
  color: #000;
  min-width: 200px;
}

.padding15 {
  padding: 15px;
}


.padding10 {
  padding: 10px;
}

.ant-booking-type-radio {
  .ant-radio-button-wrapper {
    width: 50%;
  }
}

.marginBtm15 {
  margin-bottom: 15px!important;
}

.width50per{
  width: 50%;
}

.right5p {
  right: 5%;
}

.border-common-black {
  border: 1px solid #000;
}

div.scrollmenu {
    background-color: #000;
    overflow: auto;
    white-space: nowrap;
}

.scrollmenuNew {
  background-color: #F0F1EC!important;
}

.scrollmenuNew .ant-row div {
  width: 10%;
}

div.scrollmenu div {
    display: inline-block;
    color: white;
    text-align: center;
    margin: 18px;
    text-decoration: none;
}

div.scrollmenu div a {
  color: white;
  padding: 10px
}

div.scrollmenu div a:hover, div.scrollmenu div a:focus {
  background: #FFDD00;
  border-radius: 25px;
  color: #000;
}

.mobile-tabs label {
  width: 100%;
}

.height40p {
  height: 40px !important;
}

.intro--container {
  padding: 0px 20px;
  position: absolute;
  top: 10%;
  flex-direction: row;
}

.intro--title {
  width: 30%;
  margin-right: 5%;
  font-size: 42px;
  flex-shrink: 0;
}

.intro--img {
  min-width: 100%;
  height: auto;
}

@media screen and (max-width: 1600px) {
  .intro--title {
    font-size: 38px;
  }
}

@media screen and (max-width: 1450px) {
  .intro--title {

    /*
      Fixing width because this sections image will not resize beyond its width
      and hence this fixing is safe
    */
    width: 400px;
    margin-right: 50px;
    font-size: 34px;
  }
}

@media screen and (max-width: 1100px) {
  .intro--img {
    width: 100%;
    min-width: unset;
  }

  .intro--container {
    position: relative;
    background: #f0f1ec;
    text-align: center;
    flex-direction: column;
  }

  .intro--title {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
    font-size: 2.5rem;
    padding: 10px 0px;
  }
}
/*************************
DATE TIME PICKER 
*************************/
.date-time-border{
  border-width: 1px 1px 1px 0px ;
  border-color:  @isoColor--Border;
  border-style: solid;
  border-radius: 0px 4px 4px 0px;
}
.ant-calendar-picker-input{
  border: none !important
}
.ant-calendar-picker {
  border-right: 1px solid @isoColor--Border;
}
.label-calendar{
  background-color: @isoColor--GreyBackground !important;
  display: flex !important;
  align-items: center;
  border: 1px solid @isoColor--Border !important;
}

@media screen and (max-width: 700px){
  .ant-calendar-picker{
    border-right: none;
    border-bottom: 1px solid @isoColor--Border;
  }
} 

/*************************
FEATURES
**************************/
.feature {
  padding: 6%;
}

.feature > :first-child {
  font-size: 42px;
  padding-bottom: 40px;
}

.feature__section-container {
  display: flex;
  flex-direction: column;
}

.feature__section-container__row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.feature__card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 18%;
}

@media screen and (max-width: 900px) {
  .feature {
    padding: 40px 10px;
  }

  .feature > :first-child {
    font-size: 31px;
    padding-bottom: 20px;
  }

  .feature__section-container__row {

    /*Added for IE 11*/
    min-width: 1000px
  }

  .feature__section-container {
    flex-direction: row;
    overflow: auto;
  }

  .feature__card-container {
    width: 250px;
  }
}

.fleet-container {
  top: 50%;
  transform: translateY(-50%);
}

.fleet-image--container {
  width: 150px;
  padding: 15px;
}

.fleet-image {
  max-width: 90%;
}

@media screen and (max-width: 700px) {
  .fleet-container {
    right: 50%;
    transform: translateY(-50%) translateX(50%);
  }
}

@media screen and (max-width: 470px) {
  .fleet-image--container {
    width: 100px;
    padding: 15px 5px 5px 5px;
  }
}

.brand--container {
  margin: 60px 0px;
  align-items: center;
}

.brand--decription {
  width: 60%;
  padding: 0px 60px;
}

.brand--image {
  width: 40%;
}

.about-us--container {
  padding: 5%;
}

.about-us--gallery {
  display: grid;
  grid-template-columns: auto;
  grid-auto-flow: column;
  overflow-x: auto;
  gap: 10px 16px;
}

.about-us--container > div:first-child {
  font-size: 4.2rem;
}

.about-us--text-image-container {
  display: flex;
  flex-direction: row-reverse;
}

//This is mimced by data-match-media-query in the js file for about.
@media screen and (max-width: 1100px) {

  .brand--image {
    display: none;
  }

  .brand--decription {
    flex-grow: 1;
    padding: 30px;
  }

  .about-us--container > div:first-child {
    text-align: center;
    font-size: 31px;
  }

  .about-us--text-image-container {
    display: flex;
    flex-direction: column;
  }

  // Added to prevent IE form adding extra space between child elements
  .about-us--text-image-container > div:first-child {
    overflow: auto;
  }

  .about-us--text-image-container > div:first-child > img {
    width: 100%;
  }

  .about-us--text-image-container > div:nth-child(2) {
    margin-top: 10px;
  }
}

@media screen and (max-width: 600px) {
  .brand--decription {
    padding: 20px;
  }
}

/******************
TEAM
******************/
.team-container > :first-child {
  font-size: 4.2rem;
}

.team-container__image-container {
  display: flex;
  flex-direction: row;
  overflow: auto;
}

@media screen and (min-width: 1400px) {
  .team-container__image-container {
    justify-content: center;
  }
}

.team-container__image-container__details {
  margin-right: 15px;
}

.team-container__image-container__details > img {
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .team-container > :first-child {
    font-size: 31px;
  }

  .team-container__image-container__details > img {
    width: auto;
  }
}

/*************************
DOWNLOAD APP
*************************/
.download-app-container {
  display: flex;
  flex-direction: row;
  padding: 100px 100px 0px 100px
}

.download-app-container__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.download-app-container__text__title {
  font-size: 5.4rem;
  padding-bottom: 40px;
}

.download-app-container__image {
  margin: 0 auto;
  max-width: 100%;
  height: 100%;
}

.download-app-container__image > img {
  max-width: 100%;
}

.download-app-container__text__download-image {
  width: 200px;
}

@media screen and (max-width: 1465px) {
  .download-app-container__text__title {
    font-size: 4.2rem;
  }

  .download-app-container {
    padding-right: 0px;
  }
}

@media screen and (max-width: 1200px) {
  .download-app-container {
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }

  .download-app-container__text {
    align-items: center;
    max-width: 100%;
  }

  .download-app-container__text__title {
    font-size: 31px;
    font-weight: bold;
    padding: 20px;
    text-align: center;
    width: 100%;
  }

  .download-app-container__text__download-image {
    margin-top: 10px;
    margin-bottom: 40px;
    display: block;
  }
}

/**************
Offerings
**************/
.offering {
  padding: 100px;
}

.offering > :first-child {
  font-size: 4.2rem;
}

.offering-container {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr;
  -ms-grid-columns: 1fr 1fr;
}

.offerings_category_page--2_subheader{
  font-size: 2rem;
  text-align: center;
  padding: 10px;
}

.offerings_category_page--2-container {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
  margin: 10px;
  border-left: 1px solid #ccc;
}

.offerings_category_page--2-container .offering__links{
  border-style: solid;
  border-width: 1px 1px 1px 0px;
  padding: 5px;
  // border-color: #CCC;
  display: flex;
  align-items: center;
}

.offerings_category_page--2-container .offering__links:nth-child(2n - 1){
  background: #fff;
}

.offerings_category_page--2-container .offering__links:nth-child(2n){
  background: #f1f1f3;
}

.offering-container-page--3 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-gap: 10px;
}

.offering-container__item {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  margin-right: 40px;
}

.offering-container__item--position-1 {
  -ms-grid-row: 1;
  grid-row: 1;
  -ms-grid-column: 1;
  grid-column: 1;
}

.offering-container__item--position-2 {
  -ms-grid-row: 1;
  grid-row: 1;
  -ms-grid-column: 2;
  grid-column: 2;
}

.offering-container__item--position-3 {
  -ms-grid-row: 2;
  grid-row: 2;
  -ms-grid-column: 1;
  grid-column: 1;
}

.offering-container__item--position-4 {
  -ms-grid-row: 2;
  grid-row: 2;
  -ms-grid-column: 2;
  grid-column: 2;
}

.offering-container__item--position-5 {
  -ms-grid-row: 3;
  grid-row: 3;
  -ms-grid-column: 1;
  grid-column: 1;
}

.offering-container__item--position-6 {
  -ms-grid-row: 3;
  grid-row: 3;
  -ms-grid-column: 2;
  grid-column: 2;
}

.offering-container__item--position-7 {
  -ms-grid-row: 4;
  grid-row: 4;
  -ms-grid-column: 1;
  grid-column: 1;
}
.offering-container__item--position-8 {
  -ms-grid-row: 4;
  grid-row: 4;
  -ms-grid-column: 2;
  grid-column: 2;
}
.offering-container__item--position-9 {
  -ms-grid-row: 5;
  grid-row: 5;
  -ms-grid-column: 1;
  grid-column: 1;
}
.offering-container__item--position-10 {
  -ms-grid-row: 5;
  grid-row: 5;
  -ms-grid-column: 2;
  grid-column: 2;
}
.offering-container__item--position-11 {
  -ms-grid-row: 6;
  grid-row: 6;
  -ms-grid-column: 1;
  grid-column: 1;
}
.offering-container__item--position-12 {
  -ms-grid-row: 6;
  grid-row: 6;
  -ms-grid-column: 2;
  grid-column: 2;
}

.offering-container__item--position-13 {
  -ms-grid-row: 7;
  grid-row: 7;
  -ms-grid-column: 1;
  grid-column: 1;
}

.offering-container__item--position-14 {
  -ms-grid-row: 7;
  grid-row: 7;
  -ms-grid-column: 2;
  grid-column: 2;
}

@media screen and (max-width: 1300px) {
  .offering {
    padding: 20px;
  }
}

@media screen and (max-width: 1000px) {

  .offering {
    padding: 20px 0px;
  }

  .offering > :first-child {
    font-size: 31px;
  }
  
  .offering-container {
    // grid-template-columns: repeat(auto-fit, 250px);

    // display: grid;
    display: flex;
    // grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
    // grid-auto-flow: column;
    // overflow-x: auto;
    // gap: 10px 16px;

    // -ms-grid-columns: auto auto auto auto auto auto;
    
    overflow: auto;
    
  }

  .offering-container__item {
    width: 250px;
    margin-right: 20px;
    flex-direction: column;
    min-width: 250px;
  }

  .offering-container__item img {
    width: 100%;
  }

  // .offering-container__item--position-3 {
  //   -ms-grid-row: 1;
  //   grid-row: 1;
  //   -ms-grid-column: 3;
  //   grid-column: 3;
  // }
  
  // .offering-container__item--position-4 {
  //   -ms-grid-row: 1;
  //   grid-row: 1;
  //   -ms-grid-column: 4;
  //   grid-column: 4;
  // }
  
  // .offering-container__item--position-5 {
  //   -ms-grid-row: 1;
  //   grid-row: 1;
  //   -ms-grid-column: 5;
  //   grid-column: 5;
  // }
  
  // .offering-container__item--position-6 {
  //   -ms-grid-row: 1;
  //   grid-row: 1;
  //   -ms-grid-column: 6;
  //   grid-column: 6;
  // }
}

.border-common {
  border:1px solid #e9e9e9 !important;
  
}

.borderL {
  border-left:1px solid #e9e9e9 !important;
}

.borderR {
  border-right:1px solid #e9e9e9 !important;
}

.border-right-common {
  border-right:1px solid #e5e5e4 !important;
}

.ant-switch-checked {
  background-color: #FFDD00!important;
  border-color: #FFDD00!important;
}

.paddingLeft10 {
  padding-left: 10px!important;
}

.paddingRight10 {
  padding-right: 10px!important;
}

.margin--bot15{
  margin-bottom: 15px;
}

.margin--top15{
  margin-top: 15px;
}

.margin--15p{
  margin: 15px;
}

.margin--top10{
  margin-top: 10px;
}

.marginBtm10 {
  margin-bottom: 10px!important;
}

.marginBtm0 {
  margin-bottom: 0px!important;
}

.marginLeft10 {
  margin-left: 10px!important;
}


.marginRight10 {
  margin-right: 10px!important;
}

.margin0 {
  margin: 0!important;
}

.marginTop10 {
  margin-top: 10px!important;
}

.paddingBtm0 {
  padding-bottom: 0px!important;
}

.paddingBtm10 {
  padding-bottom: 10px!important;
}

.paddingLeft80 {
  padding-left: 80px!important;
}

.paddingLeft100 {
  padding-left: 100px!important;
}

.confirm-book-mid-wrapper {
  border: 1px solid #e9e9e9;
  background-color: white; 
  margin-bottom: 10px;
}

.confirm-book-mid {
 font-size: 24px;
 text-align: center; 
 padding: 15px;
 border-top: 1px solid #e9e9e9;
 border-bottom: 1px solid #e9e9e9;
}

.confirm-booking-sep {
  border-right: 1px solid #e9e9e9;
}

.ant-collapse-header {
 background: #F2F2F2!important;
 text-align: left; 
}

//TODO remove these harcoded max-heights
.confirm-booking-form-scrollable {
  max-height: 424px;
  overflow-x: auto;
}

.event-booking-form-scrollable {
  max-height: 500px;
  overflow: auto;
}

.customize-booking-form-scrollable {
  max-height: 200px;
  overflow: auto;
}

.dark-wrapper {
  background: #e2e2e2!important;
}

.paddingTop10 {
  padding-top: 10px;
}

.bcwhite {
  background-color: #ffffff!important;
}

.top0 {
  top: 0px !important;
}

.pickupTime {
  font-size: 15px;
  border: none;
  height: inherit;

  //necessary tro avoid bootstrap CSS
  box-shadow: none !important;

  //Remove padding added by bootstrap
  padding-top: 0px;
  padding-bottom: 0px;
}

.top45 {
  top: 45%;
}

.ant-collapse-content-box {
  .ant-input {
      height: 55px!important;
  }
}

.label-custom {
  color: #000;
  opacity: 0.5;
}

.modal-align-title {

  .ant-confirm-body {
      text-align: center;
      .ant-confirm-title {
          font-size: 20px!important;
      }
  }
}

.custom-modal {
  .ant-modal-header {
    background: white;
    font-size: 16px;
    border: none;
    border-radius: 0;
    padding: 24px;  
  }
  .anticon {
    display: none;
  }
  .ant-confirm-btns {
    width: 100%;
    margin-top: 15px;
    .ant-btn-lg {
      width: 100%;
      background: #FFDD00;
      color: black;
    }
    .ant-btn-lg:hover {
      background: #FFDD00;
      color: black;
    }
  }
  .ant-modal-content {
    border-radius: 0
  }
  .ant-confirm-content {
    margin-left: 0px!important;
  }
  .ant-modal-body {
    padding: 20px!important;
  }
  .ant-modal-footer {
      border: none;
    border-radius: 0;
  }
  .modal--row_padding{
    padding-top: 10px;
  }
  .rate_modal--font-size{
    font-size: 16px;
  }
  .rate_modal--rate-font-size{
    font-size: 14px;
  }
}

@media screen and (max-width:768px){
  .custom-modal {
    .ant-modal-body {
      padding: 10px 10px!important;
    }
    .modal--row_padding{
      padding-top: 5px;
    }
    .ant-confirm-btns{
      margin-top: 10px !important;
    }
    .rate_modal--font-size{
      font-size: 14px;
    }
    .rate_modal--rate-font-size{
      font-size: 12px;
    }
    .paddingBtm10{
      padding-bottom: 5px !important;
    }
  }
}

.headerLabel {
  font-size: 19px;
  text-align: center;
  padding: 18px 0 14px 0;
  color: #000;
}

.subheaderLabel {
  font-size: 14px;
  text-align: center;
  padding: 1px 5px;
}

.green-dot {
  height: 8px;
    width: 8px;
    background-color: #2FC231;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.red-dot {
  height: 8px;
    width: 8px;
    background-color: #E54915;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.yellow-dot {
  background-color: #FFDD00;
  height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.fav-address-wrapper .ant-radio-group {
  width: 100%;
}

.bg-light-grey {
  background-color: #F1F1F3;
}

.bg-dark-grey {
  background-color: #7D7D7D;
}

.inline-block {
  display: inline-block;
}

.arrow-custom-right::before {
  display: block;
  content: "\E61F";
  font-family: "anticon" !important;
  transform: rotate(-180deg);
}

.arrow-custom-left::before {
  display: block;
  content: "\E61F";
  font-family: "anticon" !important;
}

.lineHeight1-5 {
  line-height: 1.5;
}

.small-connecting-dot {
  height: 3px;
  width: 3px;
  background-color: #898989;;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 3px;
}

.login-form {
  margin: 20px 35px;
}

.custom-panel {
  .ant-collapse-header {
    text-transform: capitalize;
  }
  .ant-collapse-content {
    padding: 0px!important;
    .ant-collapse-content-box {
        padding: 0px!important;
    }
  }
}

.car-image--container{
  width: 100px;
  height: 100px;
}
.aboutUs--img img:not(#react-modal-image-img){
  // width: 300px !important;
  height: 200px;
  width: 200px;

}

.aboutUs--img{
  // width: 300px !important;
  height: 200px;
  width: 200px;

}

.gallery--grid{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
}

.gallery--item{
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block; 
}
.height65px {
  height: 65px!important;
}

.marginTop5 {
  margin-top: 5px!important;

} 

.marginBtm5 {
  margin-bottom: 5px!important;
}

.fontWght100 {
  font-weight: 100!important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.displayBlock {
  display: block;
}

.opacity8 {
  opacity: 0.8
}

.custom-checkbox-weeks {
    line-height: 3;

  .ant-checkbox {
      display: none;
  }
  .ant-checkbox-wrapper {
    position: absolute;
  }
}

.paddingLeft0 {
  padding-left: 0px!important;
} 

.paddingRight0 {
  padding-right: 0px!important;
}

.marginRight5 {
  margin-right: 5px!important;
}

.ant-btn:hover, .ant-btn:focus {
  color: #000;
  border-color : #FFDD00!important;
}

.height25 {
  height: 25px!important;
}

.minWidth20 {
  min-width: 20px!important;
}

.width100px {
  width: 100px;
}

.paddingBtm20 {
  padding-bottom: 20px;
}

.paddingTop20 {
  padding-top: 20px;
}

.paddingBtm40 {
  padding-bottom: 40px;
}

.color-white {
  color: #fff !important;
}

.border-Radius5 {
  border-radius: 5px;
}

.slider-list, .slider-slide{
  width: 100%!important;
}

.marginBtm2 {
  margin-bottom: 2px;
}

.marginBtm5 {
  margin-bottom: 5px;
}

.btm-0 {
  bottom: 0;
}

.bg-feature {
  background: #F0F1EC;
}

.bg-team {
  background: #434343;
}

.padding60 {
  padding: 60px;
}

.padding100 {
  padding: 100px;
}

@media screen and (max-width:768px){
  .padding100{
    padding: 20px;
  }
}

.left0 {
  left: 0;
}


.verticalAlignTextBtm {
  vertical-align: text-bottom;
}

.width272 {
  width: 272px;
}

.paddingTop60 {
  padding-top: 60px!important;
}

.height272 {
  height: 272px;
}

.height225 {
  height: 225px;
}

.paddingBtm60 {
  padding-bottom: 60px;
}

.padding40 {
  padding: 40px
}

.paddingLeft60 {
  padding-left: 60px;
}

.paddingRight60 {
  padding-right: 60px;
}

.paddingLeft55 {
  padding-left: 55px;
}

.paddingRight55 {
  padding-right: 55px;
}

.height200 {
  height: 200px;
}

.height230 {
  height: 230px;
}

.margin20 {
  margin: 20px;
}

.border-static-small:after {
  content: '';
  display: block;
  width: 15%;
  padding-top: 10px;
  border-bottom: 1px solid #FFDD00
}

.border-static-mid:after {
  content: '';
  display: block;
  width: 25%;
  padding-top: 4px;
  border-bottom: 1px solid #FFDD00
}

.zIndex9 {
  z-index: 9;
}

.right0 {
  right: 0;
}


.ant-switch.ant-switch-small, .ant-switch-checked {
  border-radius: 5px!important;
    min-height: 5px!important;
    height: 5px!important;
    min-width: 35px!important;
    background-color: rgba(0, 0, 0, 0.25)!important;
    border-color:rgba(0, 0, 0, 0.25)!important;
}

.ant-switch-small:after {
  border-radius: 18px!important;
  background-color: #000!important;
  top: -6.5px!important;
  width: 16px!important;
  height: 16px!important;
}

.ant-switch-checked:after {
  border-radius: 18px!important;
  top: -6.5px!important;  
  background-color: #000!important;
  width: 16px!important;
  height: 16px!important;
}

.marginLeft1point8 {
  margin-left: 1.8px;
}

.whiteSpaceInitial {
  white-space: initial!important;
}

.reset-modal {
  .ant-modal-body {
    padding: 15px!important;
  }
  .ant-confirm-title {
    font-size: 17px!important;
  }
  .ant-confirm-btns .ant-btn-lg {
      width: 50%;
      margin: 0;
      outline: none;
         
  }
  
}

.favourites-map-view {
  .ant-modal-close {
    display: none;
  }

  .booking-form {
    background: unset;
    box-shadow: unset;
  }

  .ant-modal-body {
    padding: 15px !important;
  }
}

.favourite-address--button {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.favourite-address--button + button {
  border-left: 1px solid black !important;
  color: #000 !important;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.reset-modal {
  .ant-btn {
      outline: none;
        border: none!important;
        border-radius: 0px
  }

  .ant-btn-primary {
        border-left: 1px solid black!important;
        
      }
}

.ant-modal-mask {
  z-index: 9999;
  background-color: rgba(0,0,0,0.6);
}

@media screen and (max-width: 768px){
  .ant-modal{
    max-width: 95vw !important;
    top: 75px;
  }
  .ant-modal .ant-confirm-btns{
    display: flex;
    flex-direction: column;
  }
  .ant-modal button{
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .ant-modal button:last-child{
    margin-bottom: 0px !important;
  }
  .reset-modal .ant-btn-primary {
    border-left: none !important;
  }
  .favourite-address--button + button{
    border: none !important;
  } 
}

.ant-modal-wrap {
  z-index: 9999;
}

.opacity6 {
  opacity: 0.6;
}

.has-error .ant-select-selection {
  border-color: #f04134!important;
}

.gif-wrapper {
  position: absolute;
  right:5%;
  height: 90%;
}

.right-background {
  background: #212123;
  height: 100%;
}

.ant-back-top {
  right: 1.8rem !important;
  bottom: 2rem !important;
}

.ant-back-top-content {
  background: #ffdd00;
}

.viewport-height {
  height: 100vh;
}
.stakeholder--header {
  font-weight: bold;
  font-size: 6.3vw;
  color: @primary-color;
}
.stakeholder-wrapper {
  width: 100%;
  overflow: auto;
}

.stakeholder-left {
    float: left;
    /*width: 66%;*/
    width: 99%;
    padding: 10px;
    margin: 0 0px 0 0px;
    background: black;
}

@media (max-width: 991px){
  .stakeholder-left{
    background-color: #212123
  }
}

.stakeholder-right {
    float: left;
    padding: 10px;
    text-align: center;
    background: #F2F2F2;
    width: 33%;
}

.stakeholder-text {
  text-align: center;
  font-size: 2rem;
  line-height: 45px;
}

@media screen and (max-width: 1000px){
  .stakeholder-text {
    font-size: 4.3vw;
    line-height: 7vw;
  }
}

@media screen and (max-width: 460px){
  .fS31res{
    font-size: 2rem;
  }}

.margin--50P-auto{
  margin: 50% auto;
}

.width--40px{
  width: 40px
}

.min-map-height{
  min-height: 170px;
}

.wd--100P-ht--100P{
  width: 100%;
  height: 100%;
}

.padding--5px{
  padding: 5px;
}

.topbar--business-name{
  color: #FFDD00;
  font-size: 28px;
  font-weight: 600;
  margin-top: 4px;
}

/***********************
  USER OPERATIONS 
************************/
.user_operations-page{
  height: 100vh;
  align-items: stretch;
  background: black;
}
.user_operations-container{
  min-height: 500px;
  margin-top: 65px;
  flex: 1;
  flex-wrap: wrap;
}
.user_operations-sidebar{
  background: black;
  min-width: 200px;
}
.user_operations-booking_form{
  width: 100%;
  height: calc(100vh - 168px);
  max-width: 500px;
}
.user_operations-img_container{
  flex: 1;
  img{
    width: 100%;
    height: calc(100vh - 168px);
  }
}

/************************
CUSTOMIZE SECTION
************************/
.customize_textarea{
  min-height: 140px;
  width: 96% !important;
  resize: none;
  font-size: 14px !important;
  margin: 10px;
}
/********************
PROFILE
********************/
.profile-customer_name{
  font-size: 22px;
  font-weight: bold;
}
.profile-customer_contact_details{
  font-size: 16px;
  color: #555555;
}
.profile-customer_pic{
  position: relative;
  height: 120px;
  width: 120px;
  border-radius: 50%;
}
.profile-customer_pic_upload-container{
  width: 400px;
  height: 250px;
  .profile-customer_pic_upload{
    font-size: 30px;
    padding-left: 40px;
    padding-top: 100px;
  }
}
/**********************
SEARCH CABS 
***********************/
.search_cabs-container{
  margin-top: 65px;
  flex: 1;
  flex-wrap: wrap;
}
.search_cabs-background{
  background: @yp-background-color;
  height: 100vh;
}
.search_cabs-sidebar{
  background: black;
  min-width: 200px;
}
.search_cabs-form_container{
  width: 100%;
  height: calc(100vh - 130px);
  max-width: 500px;
}

@media screen and (max-height: 500px) {
  .search_cabs-form_container{
    height: calc(100vh);
  }
  .search_cabs-container{
    min-height: 500px;
  }
}
.search_cabs-img_container{
  flex: 1;
}
.search_cabs-img{
  width: 100%;
  height: calc(100vh - 176px);
}

.contactUs--info-padding{
  padding: 10px;
  @media (max-width: 767px) {
    padding: 0px 10px 10px 10px;
  }
}

/**********************
LOCATION SEARCH BOX
**********************/
.location_search_box{
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 100%;
  height: 40px;
  margin-top: 0px;
  padding: 0px 12px;
  border-radius: 3px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipsis;
  z-index: 9 !important;
}

.location_search_box-icon_heart{
  z-index: 100;
  left: -20px;
}


.location_search_box-icon_close{
  z-index: 100;
  left: -13px;
  font-weight: bold;
}

.map-border{
  border: 1px solid #e5e5e4;
}

.current_location-container{
  top: 20px;
  max-height: calc(100vh - 500px);
  overflow-y: scroll;
  min-height: 75px;
}

.vehicle_options{
  padding: 7px 4px;
}

.login-mobile_icon{
  color: rgba(0,0,0,.25);
}

.layout-container{
  max-width: 2000px;
  margin: auto;
}

.contact_us--close{
  line-height: 30px;
  padding: 0px 10px;
  background: lightgrey;
}

.help_us-img{
  width: 100%;
  height: auto;
}

.social_links-playstore_icon{
  width: 40px;
  height: 40px;
  margin-top: 0px !important;
}

.team_linkedin-container{
  top: -4px;
  background: white;
  width: 5px;
  border-radius: 6px;
  img{
    width: 27px;
  }
}

.navbar-profile_pic{
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.wd-24P{
  width: 24% !important;
}

.wd-76P{
  width: 76% !important;
}

@media (max-width: 400px) {
  .wd-24P{
    width: 30% !important;
  }
  
  .wd-76P{
    width: 70% !important;
  }

  .login-form{
    margin: 20px 20px;
  }
}

.main_section-height{
  height: calc(100vh - 130px);
  min-height: -webkit-fill-available;
  overflow-x: hidden;
}
.login_section-height{
  height: calc(100vh - 70px);
  min-height: 500px;
}
.main_booking_section-height{
  height: calc(100vh - 130px);
  min-height: -webkit-fill-available;
  overflow-x: hidden;
}
@media (max-width: 768px) {
  .login_section-height{
    height: calc(100vh - 170px);
    min-height: 500px;
    overflow-x: hidden;
  }
  .main_section-height{
    height: calc(100vh - 200px);
    min-height: -webkit-fill-available;
    overflow-x: hidden;
  }
  .main_booking_section-height{
    height: calc(100vh - 200px);
    min-height: -webkit-fill-available;
    overflow-x: hidden;
  }
}

.confirm--padding{
  padding: 12px 0px 0px 8px !important;
}